import React, { useState, useEffect } from "react";
import logo from "../img/logo.png";
import axios from "axios";
import { Navbar, Nav, Dropdown, Button } from "react-bootstrap";

function NavbarApp() {
  const handleClick = () => {
    window.location.href = "/logout";
  };
  let response_variable = "";
  const [guilds, setGuilds] = useState([]);
  const [selectedClan, setSelectedClan] = useState({});

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  const handleSelect = (clan) => {
    setSelectedClan(clan);
    sessionStorage.setItem("selectedClanId", clan.clan_server_id);
  };
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  const [data, setData] = useState([]);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const postData = async () => {
    try {
      let response = await axios.post(
        "https://api-private.clanwarstracker.cz/discord/userinfo/",
        { token: getCookie("access_token") },
        { headers: { "Content-Type": "application/json" } }
      );
      console.log("Odpověď od serveru:", response.data); // Zalogování úspěšné odpovědi

      setCookie("username", response.data.username, 7); // Uloží na 7 dní
      console.log("GUILDS RESPONSE:", response.data.guilds);
      sessionStorage.setItem("guilds", response.data.guilds);
      response_variable = "JSON.stringify(response.data.guilds)";
      setCookie("guilds", JSON.stringify(response.data.guilds), 7);
    } catch (error) {
      console.error("Chyba při odesílání požadavku:", error);
      if (error.response) {
        console.error("Odpověď serveru:", error.response);
      }
    }
  };

  let access_token = getCookie("access_token");
  let guilds_cookie = getCookie("guilds");
  let username = getCookie("username");
  postData();
  sleep(10000);

  useEffect(() => {
    const storedGuilds = guilds_cookie;
    const storedSelectedClanId = sessionStorage.getItem("selectedClanId");

    const guildsArray = JSON.parse(storedGuilds);
    setGuilds(guildsArray);

    if (storedSelectedClanId) {
      const foundClan = guildsArray.find(
        (clan) => clan.clan_server_id === storedSelectedClanId
      );
      if (foundClan) {
        setSelectedClan(foundClan);
      }
    } else if (guildsArray.length > 0) {
      setSelectedClan(guildsArray[0]);
      sessionStorage.setItem("selectedClanId", guildsArray[0].clan_server_id);
    }

    if (storedGuilds) {
      const guildsArray = JSON.parse(storedGuilds);
      setGuilds(guildsArray);

      if (storedSelectedClanId) {
        const foundClan = guildsArray.find(
          (clan) => clan.clan_server_id === storedSelectedClanId
        );
        if (foundClan) {
          setSelectedClan(foundClan);
        }
      } else if (guildsArray.length > 0) {
        setSelectedClan(guildsArray[0]);
        sessionStorage.setItem("selectedClanId", guildsArray[0].clan_server_id);
      }
    }
    postData();
  }, []);

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" className="px-3">
        <Navbar.Brand>
          <img
            src={logo}
            className="App-logo"
            alt="logo"
            style={{ height: "35pt" }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto mx-3">
            <Dropdown>
              <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
                <strong>{selectedClan.clan_name || "Vyberte klan"}</strong>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {guilds.map((clan, index) => (
                  <Dropdown.Item key={index} onClick={() => handleSelect(clan)}>
                    {clan.clan_name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Nav.Link href="/home">Domů</Nav.Link>
            <Nav.Link href="/battles">Historie</Nav.Link>
            <Nav.Link href="/donate">Donate</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Button variant="success" className="mx-3" disabled>
          <strong>{username}</strong>
        </Button>
        <Button variant="outline-danger" onClick={handleClick}>
          ODHLÁSIT
        </Button>
      </Navbar>{" "}
    </>
  );
}

export default NavbarApp;
