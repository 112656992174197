import logo from "../img/logo.png";
import "../App.css";
import "react-bootstrap";
import { ListGroup, Table } from "react-bootstrap";
// LOCALHOST https://discord.com/api/oauth2/authorize?client_id=1174291131618566185&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fdiscordrollback%2F&response_type=code&scope=guilds%20identify%20email%20guilds.join%20connections%20guilds.members.read
// PRODUCTION https://discord.com/api/oauth2/authorize?client_id=1174291131618566185&redirect_uri=https%3A%2F%2Fclanwarstracker.cz%2Fdiscordrollback%2F&response_type=code&scope=guilds%20identify%20email%20guilds.join%20guilds.members.read%20connections
function Home() {
  const dev = 0;
  let discordAuthUrl;
  if (dev === 1) {
    discordAuthUrl =
      "https://discord.com/api/oauth2/authorize?client_id=1174291131618566185&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fdiscordrollback%2F&scope=identify+guilds.members.read+guilds";
  } else {
    discordAuthUrl =
      "https://discord.com/api/oauth2/authorize?client_id=1174291131618566185&response_type=code&redirect_uri=https%3A%2F%2Fclanwarstracker.cz%2Fdiscordrollback%2F&scope=identify+guilds.members.read+guilds";
  }
  // Funkce pro zpracování kliknutí na tlačítko
  const handleClick = () => {
    window.location.href = discordAuthUrl;
  };
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  let readyToRedirect = false;
  if (getCookie("access_token")) {
    readyToRedirect = true;
  }
  if (readyToRedirect) {
    window.location.href = "/home";
  }
  return (
    <div className="App">
      <header className="App-header pt-4">
        <img src={logo} className="App-logo" alt="logo" />
        <button
          type="button"
          className="btn btn-outline-danger"
          onClick={handleClick}
        >
          PŘIHLÁSIT SE
        </button>
        <div className="text-center text-light py-5 px-5">
          <h1>Úvodem</h1>
          <p>
            ClanWarsTracker vznikl jako nástroj pro hráče hry War Thunder,
            respektive pro klany, které se účastní SQB a chtějí zapisovat své
            bitvy lehce, hezky a přehledně. V odstavcích níže se dostaneme k
            představení jednotlivých funkcí a taky si popíšeme, jak aplikaci
            připravit i pro Tvůj klan. Předem říkám, že nejsem frontenďák, to že
            ta aplikace nevypadá dokonale vím a určitě na tom zapracuji, nicméně
            je aktualně prioritní to, aby fungovala. Na frontend si buď jednoho
            dne sednu, nebo si někoho zaplatím (Fiverr a Indové jsou cesta :D)
          </p>
          <h1>Funkce aplikace</h1>
          <div>
            <Table striped hover variant="dark">
              <tbody>
                <tr>
                  <td>1</td>
                  <td></td>
                  <td>
                    {" "}
                    Propojení s Discordem, díky tomu neuchováváme žádné citlivé
                    údaje, do naší appky se přihlásíte pomocí Discord OAuth
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td></td>
                  <td>
                    {" "}
                    Po přihlášení můžeme vidět formulář, který slouží k zápisu.
                    Zapisujeme následující parametry:
                  </td>
                </tr>
                <tr>
                  <td>-</td>
                  <td>1</td>
                  <td>Klan proti kterému se vedla bitva</td>
                </tr>
                <tr>
                  <td>-</td>
                  <td>2</td>
                  <td>Výsledek bitvy - výhra / prohra</td>
                </tr>
                <tr>
                  <td>-</td>
                  <td>3</td>
                  <td>Počet stíhaček které protivník nasadil</td>
                </tr>
                <tr>
                  <td>-</td>
                  <td>4</td>
                  <td>Počet bombardérů které protivník nasadil</td>
                </tr>
                <tr>
                  <td>-</td>
                  <td>5</td>
                  <td>Počet helikoptér které protivník nasadil</td>
                </tr>
                <tr>
                  <td>-</td>
                  <td>6</td>
                  <td>
                    Počet protiletadlových jednotek které protivník nasadil
                  </td>
                </tr>
                <tr>
                  <td>-</td>
                  <td>7</td>
                  <td>Poznámka (například klíčové stroje)</td>
                </tr>
                <tr>
                  <td>-</td>
                  <td>8</td>
                  <td>
                    Automaticky zapisujeme i BR dané bitvy - podle aktualního
                    data
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td></td>
                  <td>
                    Možnost zobrazení historie bitev a následný výmaz (pro
                    autora zápisu)
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td></td>
                  <td>
                    Možnost práce s daty - zobrazení průměru toho, kolik jaký
                    klan nasazuje různých typů jednotek (ať už celkově, nebo na
                    určitém BR)
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <h1>Jak to můžu používat?</h1>
          <p>
            Jednoduše, klikneš{" "}
            <a href="https://discord.com/api/oauth2/authorize?client_id=1174288838055710761&permissions=8&scope=bot">
              sem
            </a>{" "}
            a na svůj server pozveš bota, je nutné, aby to dělal buď
            administrátor, nebo majitel serveru, bot totiž potřebuje oprávnění,
            která jinak nedostane a nebude správně fungovat konfigurace. Po
            konfiguraci můžete botovi odebrat práva, nesmíte ho ale vyhodit,
            jinak by appka nefungovala správně (hledá servery na kterých je jak
            uživatel, tak bot).
          </p>
          <p>
            Po přidání bota na server musíme spustit příkaz, to už může udělat
            kdokoli, takže je potřeba to udělat dřív než nějaký troll, podruhé
            to už nepůjde a je třeba manualně zresetovat konfiguraci
            (support@clanwarstracker.cz). Příkaz je /configure_server, tento
            příkaz vyžaduje několik parametrů a Discord tě tím sám provede,
            nicméně si je tu stejně zmíníme.
          </p>
          <p>
            clan_name reprezentuje název klanu, respektive jeho zkratku (v našem
            případě "LEGIE", samozřejmě použijete zkratku svého klanu). |
            write_role je role, která bude mít oprávnění na zápis, toto je spíše
            příprava na budoucí update. | mod_role je role, která bude mít
            přístup ke konfiguraci klanu, taktéž se jedná o přípravu na budoucí
            update. | cw_alert_channel_id je kanál, do kterého se budou odesílat
            výsledky klanových bitev. | app_update_channel_id je kanál, do
            kterého bude chodit upozornění o významných aktualizacích a
            informace týkající se appky (plánované odstávky apod.), nechodí to
            často, doporučuji to hodit buď do klanového chatu nebo do oznámení.
          </p>
          <p>
            Po odeslání dostaneme hlášku o tom, že byl server úspěšně
            nakonfigurován, společně s tím se odešlou testovací zprávy, které
            slouží k ověření, že vše funguje tak jak má. Nyní můžeš appku
            používat, stačí výše kliknout na "Přihlásit se" a můžeš appku vesele
            používat. Neboj se si udělat zkušební zápis, pak ho můžeš smazat.
          </p>
        </div>
      </header>
    </div>
  );
}

export default Home;
