import logo from "../img/logo.png";

function NotFound() {
  const handleClick = () => {
    window.location.href = "/";
  };
  return (
    <div className="App">
      <header className="App-header">
        <h1>Bohužel jsi se ztratil.</h1>
        <h1>Klikni na tlačítko níže pro návrat na hlavní stránku.</h1>
        <img src={logo} className="App-logo" alt="logo" />
        <button
          type="button"
          className="btn btn-outline-danger"
          onClick={handleClick}
        >
          DOMŮ
        </button>
      </header>
    </div>
  );
}

export default NotFound;
