import React, { useState, useEffect } from "react";
import logo from "../img/logo.png";
import axios from "axios";
import "../App.css";
import NavbarApp from "../components/Navbar";

function HomePage() {
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertErr, setShowAlertErr] = useState(false);
  const handleClick = () => {
    window.location.href = "/logout";
  };
  let response_variable = "";
  const [guilds, setGuilds] = useState([]);
  const [selectedClan, setSelectedClan] = useState({});

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const postData = async () => {
    try {
      let response = await axios.post(
        "https://api-private.clanwarstracker.cz/discord/userinfo/",
        { token: getCookie("access_token") },
        { headers: { "Content-Type": "application/json" } }
      );
      console.log("Odpověď od serveru:", response.data); // Zalogování úspěšné odpovědi

      setCookie("username", response.data.username, 7); // Uloží na 7 dní
      console.log("GUILDS RESPONSE:", response.data.guilds);
      sessionStorage.setItem("guilds", response.data.guilds); // Pole je třeba převést na řetězec
      response_variable = "JSON.stringify(response.data.guilds)";
      setCookie("guilds", JSON.stringify(response.data.guilds), 7);
    } catch (error) {
      console.error("Chyba při odesílání požadavku:", error);
      if (error.response) {
        console.error("Odpověď serveru:", error.response);
      }
    }
  };

  let access_token = getCookie("access_token");
  let guilds_cookie = getCookie("guilds");
  let username = getCookie("username");
  let user_id = getCookie("user_id");
  postData();
  console.log("zahajuji sleep");
  sleep(10000);
  console.log("dokončen sleep");
  console.log("TEST");
  console.log("ACCESS TOKEN COOKIE");
  console.log(access_token);
  console.log("USER ID");
  console.log(user_id);
  console.log("GUILDS COOKIE");
  console.log(guilds_cookie);
  console.log("GUILDS SESSION");
  console.log(sessionStorage.getItem("guilds"));
  console.log("TEST END");

  console.log("proměnná1:", response_variable);
  useEffect(() => {
    console.log("proměnná2:", response_variable);
    const storedGuilds = guilds_cookie;
    const storedSelectedClanId = sessionStorage.getItem("selectedClanId");

    if (storedGuilds) {
      const guildsArray = JSON.parse(storedGuilds);
      setGuilds(guildsArray);

      // Najde a nastaví vybraný klan na základě uloženého ID
      if (storedSelectedClanId) {
        const foundClan = guildsArray.find(
          (clan) => clan.clan_server_id === storedSelectedClanId
        );
        if (foundClan) {
          setSelectedClan(foundClan);
        }
      } else if (guildsArray.length > 0) {
        setSelectedClan(guildsArray[0]);
        sessionStorage.setItem("selectedClanId", guildsArray[0].clan_server_id);
      }
    }
    postData();
  }, []);

  const handleSelect = (clan) => {
    setSelectedClan(clan);
    sessionStorage.setItem("selectedClanId", clan.clan_server_id);
  };

  const [clan, setClan] = useState("");
  const [result, setResult] = useState(1);
  const [air, setAir] = useState(0);
  const [bomber, setBomber] = useState(0);
  const [heli, setHeli] = useState(0);
  const [antiair, setAntiair] = useState(0);
  const [note, setNote] = useState("N/A");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      zapsal: username,
      user_id: user_id,
      klan: clan,
      vysledek: parseInt(result, 10),
      air: parseInt(air, 10),
      bomber: parseInt(bomber, 10),
      heli: parseInt(heli, 10),
      antiair: parseInt(antiair, 10),
      note: note,
      by_clan: selectedClan.clan_server_id,
    };

    // Tady byste měli odeslat data pomocí POST požadavku
    try {
      const response = await axios.post(
        "https://api-private.clanwarstracker.cz/cw/",
        data
      );
      console.log("Odpověď od serveru:", response.data);
      setShowAlert(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        setShowAlert(false);
      }, 20000);
    } catch (error) {
      console.error("Chyba při odesílání dat:", error);
      setShowAlertErr(true);
      window.scrollTo(0, 0);
      setTimeout(() => {
        setShowAlertErr(false);
      }, 20000);
    }
  };
  return (
    <div className="App">
      <NavbarApp />
      <div className="ColorSet">
        {showAlert && (
          <div className="alert alert-success mt-5" role="alert">
            Bitva byla úspěšně zapsána!
          </div>
        )}
        {showAlertErr && (
          <div className="alert alert-danger mt-5" role="alert">
            <strong>Došlo k chybě při zápisu</strong>
          </div>
        )}
        <div className="mt-3">
          <h1>
            <u>{selectedClan.clan_name}</u>
          </h1>
          <form onSubmit={handleSubmit}>
            <fieldset>
              <div className="mb-3">
                <label htmlFor="cw_clan" className="form-label">
                  Klan proti kterému se hrálo
                </label>
                <input
                  type="text"
                  id="cw_clan"
                  className="form-control text-center"
                  placeholder="JMÉNO KLANU"
                  onChange={(e) => setClan(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="cw_result" className="form-label">
                  Výsledek bitvy
                </label>
                <select
                  id="cw_result"
                  className="form-select"
                  onChange={(e) => setResult(e.target.value)}
                >
                  <option value={1}>Výhra</option>
                  <option value={0}>Prohra</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="cw_air" className="form-label">
                  Počet stíhaček
                </label>
                <select
                  id="cw_air"
                  className="form-select"
                  onChange={(e) => setAir(e.target.value)}
                >
                  <option value={0}>0 stíhaček</option>
                  <option value={1}>1x stíhačka</option>
                  <option value={2}>2x stíhačky</option>
                  <option value={3}>3x stíhačky</option>
                  <option value={4}>4x stíhačky</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="cw_bomber" className="form-label">
                  Počet bombardérů
                </label>
                <select
                  id="cw_bomber"
                  className="form-select"
                  onChange={(e) => setBomber(e.target.value)}
                >
                  <option value={0}>0 bombardérů</option>
                  <option value={1}>1x bombardér</option>
                  <option value={2}>2x bombardéry</option>
                  <option value={3}>3x bombardéry</option>
                  <option value={4}>4x bombardéry</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="cw_heli" className="form-label">
                  Počet Heli
                </label>
                <select
                  id="cw_heli"
                  className="form-select"
                  onChange={(e) => setHeli(e.target.value)}
                >
                  <option value={0}>0 heli</option>
                  <option value={1}>1x heli</option>
                  <option value={2}>2x heli</option>
                  <option value={3}>3x heli</option>
                  <option value={4}>4x heli</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="cw_antiair" className="form-label">
                  Počet AntiAirů
                </label>
                <select
                  id="cw_antiair"
                  className="form-select"
                  onChange={(e) => setAntiair(e.target.value)}
                >
                  <option value={0}>0 AAA</option>
                  <option value={1}>1x AAA</option>
                  <option value={2}>2x AAA</option>
                  <option value={3}>3x AAA</option>
                  <option value={4}>4x AAA</option>
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="cw_note" className="form-label">
                  Poznámka
                </label>
                <input
                  type="text"
                  id="cw_note"
                  className="form-control text-center"
                  placeholder="Vyplň prosím poznámku"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-outline-success">
                Zapsat CW
              </button>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
