import React, { useState, useEffect } from "react";
import axios from "axios";
import ProgressBar from "react-bootstrap/ProgressBar";
import Table from "react-bootstrap/Table";
import logo from "../img/logo.png";
import qr_dono from "../img/qr_dono.jpg";
import "../App.css";
import NavbarApp from "../components/Navbar";

function Donate() {
  const required_amount = 150;
  const AllDonatorsTable = ({ data }) => (
    <div>
      <h2 className="text-light">Všichni donatoři</h2>
      <Table className="table-dark" hover bordered responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Uživatelské jméno</th>
            <th>Klan</th>
            <th>Částka</th>
            <th>Datum</th>
          </tr>
        </thead>
        <tbody>
          {data.map((donator) => (
            <tr key={donator.id}>
              <td>{donator.id}</td>
              <td>{donator.username}</td>
              <td>{donator.clan}</td>
              <td>{donator.amount} Kč</td>
              <td>{donator.date}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
  const TopDonatorsTable = ({ data }) => (
    <div>
      <h2 className="text-light">Největší donatoři</h2>
      <Table className="table-dark" hover bordered responsive="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Uživatelské jméno</th>
            <th>Celková částka</th>
          </tr>
        </thead>
        <tbody>
          {data.map((donator, index) => (
            <tr key={index}>
              <td>{index + 1}.</td>
              <td>{donator.username}</td>
              <td>{donator.total_amount} Kč</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
  const [recentDonators, setRecentDonators] = useState([]);
  const [topDonators, setTopDonators] = useState([]);

  useEffect(() => {
    fetch("https://api-private.clanwarstracker.cz/donator/recentdonators")
      .then((response) => response.json())
      .then((data) => setRecentDonators(data.recent_donators));
  }, []);
  useEffect(() => {
    fetch("https://api-private.clanwarstracker.cz/donator/topdonators")
      .then((response) => response.json())
      .then((data) => setTopDonators(data));
  }, []);
  const [data, setData] = useState({
    percents: 0,
    obtained_amount: 0,
    required_amount: 0,
  });
  const [type, setType] = useState("warning");

  useEffect(() => {
    axios
      .get("https://api-private.clanwarstracker.cz/donator/monthprogress", {
        headers: {
          "Content-Type": "application/json",
          // Přidejte další potřebné hlavičky zde
        },
      })
      .then((response) => {
        // Zpracování dat získaných z API
        const { percents, obtained_amount, required_amount } = response.data;
        setData({ percents, obtained_amount, required_amount });
        setType(obtained_amount >= required_amount ? "success" : "warning");
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  let readyToRedirect = false;
  if (getCookie("access_token")) {
    readyToRedirect = false;
  }
  if (readyToRedirect) {
    window.location.href = "/home";
  }
  return (
    <>
      <NavbarApp />
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>DONATE</h1>
          <p className="mx-5">
            Ačkoli je aplikace bezplatná, dovolil jsem si přidat sem tuto
            stránku. To že je aplikace bezplatná neznamená, že to nic nestojí.{" "}
            <br />
            Máme v tomto projektu několik desítek hodin mravenčí práce, několik
            litrů vypitého kafe a ne vždy to jde dělat střízlivej, padlo i
            několik panáků. <br />
            Pokud by jsi si teda řekl, že bys chtěl, ať už za sebe, nebo za Váš
            klan, podpořit náš projekt, níže je uvedený QR kód, do zprávy pro
            příjemce napiš prosím své jméno, které bude společně s klanem, který
            jistě taky uvedeš, uvedeno mezi podporovateli společně s celkovou
            částkou. <br />
            Příspěvek není povinnost, je dobrovolný a nevratný. Jistě sis všiml
            proužku tady dole, ten značí, kolik % z měsíčních nákladů bylo
            pokryto. Účet na který platba přijde je vyhrazený jen a pouze k
            tomuto účelu.
          </p>
          <img src={qr_dono} className="App-logo" alt="QR Donate" />
        </header>
        <div
          className="progress-bar-container px-5 py-5"
          style={{ position: "relative" }}
        >
          <ProgressBar>
            <ProgressBar striped animated variant={type} now={data.percents} />
            <ProgressBar
              striped
              animated
              variant="danger"
              now={100 - data.percents}
            />
          </ProgressBar>
          <div
            style={{
              position: "absolute",
              width: "100%",
              textAlign: "center",
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            {`${data.percents}% || ${data.obtained_amount} Kč z potřebných ${required_amount} Kč`}
          </div>
        </div>
        <div className="px-5 pb-5">
          <TopDonatorsTable data={topDonators} />
          <AllDonatorsTable data={recentDonators} />
        </div>
      </div>
    </>
  );
}

export default Donate;
