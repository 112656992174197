import React, { useEffect, useState } from "react";
import axios from "axios";
import "../App.css";

function Logout() {
  const [readyToRedirect, setReadyToRedirect] = useState(false);
  const UriParams = new URLSearchParams(window.location.search);
  const code = UriParams.get("code");

  function unsetCookie(cookieName) {
    document.cookie =
      cookieName + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  }

  useEffect(() => {
    const fetchData = async () => {
      unsetCookie("username");
      unsetCookie("access_token");
      unsetCookie("guilds");
      setReadyToRedirect(true); // Nastaví stav pro povolení přesměrování
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (readyToRedirect) {
      window.location.href = "/";
    }
  }, [readyToRedirect]);
}

export default Logout;
