import React, { useState, useEffect } from "react";
import logo from "../img/logo.png";
import axios from "axios";
import NavbarApp from "../components/Navbar";
import "../App.css";
import { Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

function Battles() {
  const [lookupBr, setLookupBr] = useState({ klan: "", br: "" });
  const [lookupAll, setLookupAll] = useState({ klan: "" });
  const [showBr, setShowBr] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const [showResponseModalBr, setshowResponseModalBr] = useState(false);
  const [showResponseModalAll, setshowResponseModalAll] = useState(false);
  const [apiResponse, setApiResponse] = useState({});

  const handleCloseBr = () => setShowBr(false);
  const handleShowBr = () => setShowBr(true);
  const handleCloseAll = () => setShowAll(false);
  const handleShowAll = () => setShowAll(true);

  const handleLookupBrSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://api-private.clanwarstracker.cz/lookup/br/",
        {
          klan: lookupBr.klan,
          by_clan: sessionStorage.getItem("selectedClanId"),
          br: lookupBr.br,
        }
      );
      console.log("Odpověď z API:", response.data);
      setApiResponse(response.data);
      setShowBr(false);
      setshowResponseModalBr(true);
    } catch (error) {
      console.error("Chyba při odesílání požadavku:", error);
    }
  };

  const handleLookupAllSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://api-private.clanwarstracker.cz/lookup/all/",
        {
          klan: lookupAll.klan,
          by_clan: sessionStorage.getItem("selectedClanId"),
        }
      );
      console.log("Odpověď z API:", response.data);
      setApiResponse(response.data);
      setShowAll(false);
      setshowResponseModalAll(true);
    } catch (error) {
      console.error("Chyba při odesílání požadavku:", error);
    }
  };

  let selectedClanAPI = "";
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertErr, setShowAlertErr] = useState(false);
  const handleClick = () => {
    window.location.href = "/logout";
  };
  let response_variable = "";
  const [guilds, setGuilds] = useState([]);
  const [selectedClan, setSelectedClan] = useState({});

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  const handleSelect = (clan) => {
    setSelectedClan(clan);
    sessionStorage.setItem("selectedClanId", clan.clan_server_id);
  };

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  const [data, setData] = useState([]);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const postData = async () => {
    try {
      let response = await axios.post(
        "https://api-private.clanwarstracker.cz/discord/userinfo/",
        { token: getCookie("access_token") },
        { headers: { "Content-Type": "application/json" } }
      );
      console.log("Odpověď od serveru:", response.data); // Zalogování úspěšné odpovědi

      setCookie("username", response.data.username, 7); // Uloží na 7 dní
      console.log("GUILDS RESPONSE:", response.data.guilds);
      sessionStorage.setItem("guilds", response.data.guilds);
      response_variable = "JSON.stringify(response.data.guilds)";
      setCookie("guilds", JSON.stringify(response.data.guilds), 7);
    } catch (error) {
      console.error("Chyba při odesílání požadavku:", error);
      if (error.response) {
        console.error("Odpověď serveru:", error.response);
      }
    }
  };

  let access_token = getCookie("access_token");
  let guilds_cookie = getCookie("guilds");
  let username = getCookie("username");
  let user_id = getCookie("user_id");
  postData();
  sleep(10000);

  const handleLookupBrChange = (e) => {
    setLookupBr({ ...lookupBr, [e.target.name]: e.target.value });
  };

  const handleLookupAllChange = (e) => {
    setLookupAll({ ...lookupAll, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const storedGuilds = guilds_cookie;
    const storedSelectedClanId = sessionStorage.getItem("selectedClanId");

    const guildsArray = JSON.parse(storedGuilds);
    setGuilds(guildsArray);

    // Najde a nastaví vybraný klan na základě uloženého ID
    if (storedSelectedClanId) {
      const foundClan = guildsArray.find(
        (clan) => clan.clan_server_id === storedSelectedClanId
      );
      if (foundClan) {
        setSelectedClan(foundClan);
      }
    } else if (guildsArray.length > 0) {
      setSelectedClan(guildsArray[0]);
      sessionStorage.setItem("selectedClanId", guildsArray[0].clan_server_id);
    }

    if (storedGuilds) {
      const guildsArray = JSON.parse(storedGuilds);
      setGuilds(guildsArray);

      // Najde a nastaví vybraný klan na základě uloženého ID
      if (storedSelectedClanId) {
        const foundClan = guildsArray.find(
          (clan) => clan.clan_server_id === storedSelectedClanId
        );
        if (foundClan) {
          setSelectedClan(foundClan);
        }
      } else if (guildsArray.length > 0) {
        setSelectedClan(guildsArray[0]);
        sessionStorage.setItem("selectedClanId", guildsArray[0].clan_server_id);
      }
    }
    postData();
  }, []);

  useEffect(() => {
    console.log(
      "DOTAZUJI SE NA API PRO ZÍSKÁNÍ HISTORIE BITEV.",
      selectedClan.clan_server_id
    );
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://api-private.clanwarstracker.cz/vypis/cw/",
          { clan: selectedClan.clan_server_id },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setData(response.data.all_cw);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (selectedClan.clan_server_id) {
      fetchData();
    }
  }, [selectedClan.clan_server_id]);

  const deleteBattle = async (cw_id) => {
    try {
      await axios.delete(`https://api-private.clanwarstracker.cz/cw/${cw_id}/`);
      console.log("Bitva smazána");
      window.location.href = "/battles";
      window.scrollTo(0, 0);
    } catch (error) {
      console.error("Chyba při mazání bitvy:", error);
    }
  };

  return (
    <div className="App">
      <NavbarApp />
      <div className="ColorSet">
        <div className="mt-3 px-5 py-5">
          <h1>
            <u>{selectedClan.clan_name} - Historie bitev</u>
          </h1>
          <Button
            variant="outline-secondary"
            className="mx-2"
            onClick={handleShowBr}
          >
            LOOKUP BR
          </Button>
          <Button
            variant="outline-secondary"
            className="mx-2"
            onClick={handleShowAll}
          >
            LOOKUP ALL
          </Button>
          <Modal
            show={showResponseModalBr}
            onHide={() => setshowResponseModalBr(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                LOOKUP na klan {apiResponse.clan_name} ({apiResponse.br})
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <strong>Celkem klanových bitev:</strong>{" "}
                {apiResponse.totalklanovky}
              </p>
              <p>
                <strong>Výhry:</strong> {apiResponse.wins}
              </p>
              <p>
                <strong>Úspěšnost:</strong> {apiResponse.success_rate} %
              </p>
              <p>
                <strong>Průměrný počet tanků:</strong> {apiResponse.avg_tanks}
              </p>
              <p>
                <strong>Průměrný počet letadel:</strong> {apiResponse.avg_air}
              </p>
              <p>
                <strong>Průměrný počet protiletadlových jednotek:</strong>{" "}
                {apiResponse.avg_antiair}
              </p>
              <p>
                <strong>Průměrný počet bombardérů:</strong>{" "}
                {apiResponse.avg_bomber}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                onClick={() => setshowResponseModalBr(false)}
              >
                Zavřít
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showResponseModalAll}
            onHide={() => setshowResponseModalAll(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>LOOKUP na klan {apiResponse.clan_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <strong>Celkem klanových bitev:</strong>{" "}
                {apiResponse.totalklanovky}
              </p>
              <p>
                <strong>Výhry:</strong> {apiResponse.wins}
              </p>
              <p>
                <strong>Úspěšnost:</strong> {apiResponse.success_rate} %
              </p>
              <p>
                <strong>Průměrný počet tanků:</strong> {apiResponse.avg_tanks}
              </p>
              <p>
                <strong>Průměrný počet letadel:</strong> {apiResponse.avg_air}
              </p>
              <p>
                <strong>Průměrný počet protiletadlových jednotek:</strong>{" "}
                {apiResponse.avg_antiair}
              </p>
              <p>
                <strong>Průměrný počet bombardérů:</strong>{" "}
                {apiResponse.avg_bomber}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                onClick={() => setshowResponseModalAll(false)}
              >
                Zavřít
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showBr} animation onHide={handleCloseBr}>
            <Modal.Header closeButton>
              <Modal.Title className="text-center">
                LOOKUP NA KONKRÉTNÍM BR
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleLookupBrSubmit}>
                <Form.Group className="mb-3" controlId="LookupBrForm.ClanName">
                  <Form.Label>Klan který chceš hledat</Form.Label>
                  <Form.Control
                    type="text"
                    name="klan"
                    value={lookupBr.klan}
                    onChange={handleLookupBrChange}
                    placeholder="KLAN"
                    autoFocus
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="LookupBrForm.Br">
                  <Form.Label>
                    BR (odděluj desetinnou tečkou, nikoli čárkou)
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="br"
                    value={lookupBr.br}
                    placeholder="8.7"
                    onChange={handleLookupBrChange}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleCloseBr}>
                Zavřít
              </Button>
              <Button variant="success" onClick={handleLookupBrSubmit}>
                Hledat
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showAll} animation onHide={handleCloseAll}>
            <Modal.Header closeButton>
              <Modal.Title className="text-center">
                LOOKUP NA VŠECH BR
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleLookupAllSubmit}>
                <Form.Group className="mb-3" controlId="LookupBrForm.ClanName">
                  <Form.Label>Klan který chceš hledat</Form.Label>
                  <Form.Control
                    type="text"
                    name="klan"
                    value={lookupAll.klan}
                    onChange={handleLookupAllChange}
                    placeholder="KLAN"
                    autoFocus
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleCloseAll}>
                Zavřít
              </Button>
              <Button variant="success" onClick={handleLookupAllSubmit}>
                Hledat
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="px-5 py-5">
            <Table
              className="table-dark bordered table-striped table-hover table-responsive table-sm"
              responsive="lg"
            >
              <thead>
                <tr>
                  <th>Zapsal</th>
                  <th>Klan</th>
                  <th>Air</th>
                  <th>Bomber</th>
                  <th>Heli</th>
                  <th>AntiAir</th>
                  <th>Ground</th>
                  <th>Note</th>
                  <th>BR</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr
                    key={item.id}
                    className={
                      item.vysledek === 1
                        ? "table-success"
                        : item.vysledek === 0
                        ? "table-danger"
                        : "table-warning"
                    }
                  >
                    <td>{item.zapsal}</td>
                    <td>{item.klan}</td>
                    <td>{item.air}</td>
                    <td>{item.bomber}</td>
                    <td>{item.heli}</td>
                    <td>{item.antiair}</td>
                    <td>{item.ground}</td>
                    <td>{item.note}</td>
                    <td>{item.br}</td>
                    {item.zapsal === username &&
                      username !== "trnass" &&
                      username !== "__bini" && <td></td>}
                    {(item.zapsal === username ||
                      username === "trnass" ||
                      username === "__bini") && (
                      <td>
                        <button
                          className="btn btn-outline-danger"
                          onClick={() => deleteBattle(item.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Battles;
