import React, { useEffect, useState } from "react";
import axios from "axios";
import "../App.css";

function Rollback() {
  const [readyToRedirect, setReadyToRedirect] = useState(false);
  const UriParams = new URLSearchParams(window.location.search);
  const code = UriParams.get("code");

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const postDataToken = async () => {
    try {
      let response = await axios.post(
        "https://api-private.clanwarstracker.cz/discord/access_token/",
        { code },
        { headers: { "Content-Type": "application/json" } }
      );
      console.log("Odpověď od serveru:", response.data); // Zalogování úspěšné odpovědi

      setCookie("access_token", response.data.access_token, 7);
      console.log("RESPONSE TOKEN:", response.data.access_token);
      console.log("COOKIE TOKEN VALUE:", getCookie("access_token"));
    } catch (error) {
      console.error("Chyba při odesílání požadavku:", error);
      if (error.response) {
        console.error("Odpověď serveru:", error.response);
      }
    }
  };

  const postData = async () => {
    try {
      let response = await axios.post(
        "https://api-private.clanwarstracker.cz/discord/userinfo/",
        { token: getCookie("access_token") },
        { headers: { "Content-Type": "application/json" } }
      );
      console.log("Odpověď od serveru:", response.data); // Zalogování úspěšné odpovědi

      setCookie("username", response.data.username, 7); // Uloží na 7 dní
      setCookie("user_id", response.data.id, 7); // Uloží na 7 dní
      setCookie("guilds", JSON.stringify(response.data.guilds), 7);
      console.log("GUILDS:", response.data.guilds);
    } catch (error) {
      console.error("Chyba při odesílání požadavku:", error);
      if (error.response) {
        console.error("Odpověď serveru:", error.response);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (code) {
        await postDataToken();
        await postData();
        setReadyToRedirect(true); // Nastaví stav pro povolení přesměrování
      }
    };

    fetchData();
  }, [code]);

  useEffect(() => {
    if (readyToRedirect) {
      window.location.href = "/home";
    }
  }, [readyToRedirect]);
}

export default Rollback;
