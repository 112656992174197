import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import Home from "./pages/Home";
import Rollback from "./pages/Rollback";
import HomePage from "./pages/HomePage";
import NotFound from "./pages/NotFound";
import Admin from "./pages/Admin";
import Logout from "./pages/Logout";
import Battles from "./pages/Battles";
import Donate from "./pages/Donate";

function App() {
  return (
    <Router baseurl="/">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/battles" element={<Battles />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/logout" element={<Logout />} />

        <Route path="/admin" element={<Admin />} />

        <Route path="/discordrollback" element={<Rollback />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
